import { useState } from 'react';
import { useAuth } from '@features/auth/hooks/useAuth';
import { useClubRoles } from '@features/auth/hooks/useClubRoles';
import { useClubMembersQuery } from '@features/club/api/hooks';
import { useClubGroupProps } from '@features/club/hooks/useClubGroupProps';
import { ClubCardParticipants } from './ClubCardParticipants';

type Sort = 'RatingClassic' | 'Alphabet';

type Props = { clubId: string; className?: string };

export function ClubCardParticipantsWidget({ className, clubId }: Props) {
  const { user } = useAuth();
  const [tab, setTab] = useState<Sort>('RatingClassic');
  const { isManager } = useClubRoles(clubId);

  const { data, isPending } = useClubMembersQuery(clubId, {
    sortBy: tab,
    status: 'Active',
  });

  const groupProps = useClubGroupProps(clubId);

  return (
    <ClubCardParticipants
      className={className}
      tab={tab}
      setTab={setTab}
      members={data}
      isPending={isPending}
      highlightedUserId={user?.userId}
      isManager={isManager}
      {...groupProps}
    />
  );
}
