import { Fragment } from 'react';
import { escapeRegex } from '@libs/escapeRegexp';
import styles from './HighlightedText.module.css';

export type Props = {
  className?: string;
  highlight?: string;
  text: string;
};
export function HighlightedText({ className, highlight, text }: Props) {
  if (!highlight?.trim()) {
    return <span>{text}</span>;
  }
  const regex = new RegExp(`${escapeRegex(highlight)}`, 'gi');
  const parts = text.split(regex);
  const matches = text.match(regex);
  let m = 0;

  if (!matches) return text;

  return (
    <span className={className}>
      {parts.map((part, i) => (
        <Fragment key={i}>
          {part}
          <span key={'h' + m} className={styles.highlighted}>
            {matches[m++]}
          </span>
        </Fragment>
      ))}
    </span>
  );
}
