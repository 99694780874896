import { CSSProperties, forwardRef, ReactNode } from 'react';
import { cx } from '@libs/classnames';
import styles from './Card.module.css';

export type Props = {
  className?: string;
  style?: CSSProperties;
  active?: boolean;
  onClick?: () => void;
  disabled?: boolean;
  children: ReactNode;
};
export const Card = forwardRef<HTMLDivElement, Props>(function CardInner(
  { className, onClick, style, active, disabled, children }: Props,
  ref,
) {
  return (
    <div
      ref={ref}
      style={style}
      className={cx(className, styles.root, {
        [styles.active]: active,
        [styles.disabled]: disabled,
      })}
      onClick={onClick}
    >
      {children}
    </div>
  );
});
