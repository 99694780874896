import { useCallback } from 'react';
import { MemberDto, ParticipantDto } from '@features/shared/api/typings';
import { CardParticipantList } from '@features/shared/ui/CardParticipantList';
import { cx } from '@libs/classnames';
import { Loader } from '@ui/components/Loader';
import { Tabs } from '@ui/components/Tabs';
import { ThemeButton } from '@ui/components/ThemeButton';
import styles from './ClubParticipantsForm.module.css';

export type ClubParticipantFormStatus =
  | 'JoinRequest'
  | 'Active'
  | 'Deleted'
  | 'Invited';

const tabs = [
  { value: 'Active', label: 'Активные' },
  { value: 'Invited', label: 'Приглашенные' },
  { value: 'JoinRequest', label: 'Заявки' },
  { value: 'Deleted', label: 'Удаленные' },
];

export type Props = {
  className?: string;
  onDelete: (userId: string) => void;
  onAccept: (userId: string) => void;
  onReject: (userId: string) => void;
  participants: MemberDto[];
  status: ClubParticipantFormStatus;
  setStatus: (status: ClubParticipantFormStatus) => void;
  isLoading?: boolean;
  currentUserId?: string;
  groupTitle?: string;
  onGroupTitleChange?: (newGroupTitle: string | null) => void;
  onMemberGroupNameChange?: (userId: string, groupName: string | null) => void;
  memberGroupNameOptions?: string[];
};

export function ClubParticipantsForm({
  className,
  participants,
  onDelete,
  onAccept,
  onReject,
  status,
  setStatus,
  isLoading,
  currentUserId,
  groupTitle,
  onGroupTitleChange,
  onMemberGroupNameChange,
  memberGroupNameOptions,
}: Props) {
  const renderActions = useCallback(
    (participant: MemberDto | ParticipantDto) => {
      if (status === 'Active') {
        return (
          <ThemeButton compact onClick={() => onDelete(participant.userId)}>
            Удалить
          </ThemeButton>
        );
      } else if (status === 'JoinRequest') {
        return (
          <div className={styles.buttons}>
            <ThemeButton compact onClick={() => onAccept(participant.userId)}>
              Принять
            </ThemeButton>
            <ThemeButton compact onClick={() => onReject(participant.userId)}>
              Отклонить
            </ThemeButton>
          </div>
        );
      }
    },
    [status, onDelete, onAccept, onReject],
  );

  return (
    <div className={cx(className, styles.root)}>
      <Tabs
        tabs={tabs}
        value={status}
        onChange={(status: string | number | boolean) =>
          setStatus(status as ClubParticipantFormStatus)
        }
      />

      {(isLoading && <Loader className={styles.loader} centered />) || (
        <CardParticipantList
          preset="club"
          participants={participants}
          renderParticipantActions={renderActions}
          isManager
          highlightedUserId={currentUserId}
          groupTitle={groupTitle}
          onGroupTitleChange={onGroupTitleChange}
          onMemberGroupNameChange={onMemberGroupNameChange}
          memberGroupNameOptions={memberGroupNameOptions}
        />
      )}
    </div>
  );
}
