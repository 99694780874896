import { RefObject, useCallback } from 'react';
import { useClubRoles } from '@features/auth/hooks/useClubRoles';
import { useClubCardQuery } from '@features/club/api/hooks';
import { useUserSocket } from '@features/shared/api';
import {
  updateTournamentCardQuery,
  useParticipantActionTournamentMutation,
  usePublishTournamentMutation,
  useTournamentCardQuery,
  useTournamentResultXlsQuery,
} from '@features/tournament/api/hooks';
import { useTournamentStates } from '@features/tournament/hooks/useTournamentStates';
import { TournamentCardPageProps } from '@features/tournament/pages/TournamentCardPage/index';
import { useImmediateEffect } from '@libs/hooks/useImmediateEffect';
import { useQueryClient } from '@tanstack/react-query';
import { useNavigate } from '@tanstack/react-router';
import { useErrorNotification } from '@ui/components/ErrorsNotificationProvider';

export const useTournamentCard = ({
  clubId,
  tournamentId,
  refParticipants,
}: {
  clubId: string;
  tournamentId: string;
  refParticipants: RefObject<{ refetch: () => void }>;
}): Omit<TournamentCardPageProps, 'games' | 'participants'> => {
  const ql = useQueryClient();
  const navigate = useNavigate();
  const { report } = useErrorNotification();
  const { data: club, isPending: isClubPending } = useClubCardQuery(clubId);
  const {
    data: tournament,
    isPending: isTournamentPending,
    refetch,
  } = useTournamentCardQuery(clubId, tournamentId);

  const { isManager } = useClubRoles(clubId);

  const {
    mutateAsync: publishTournament,
    isPending: isPublishing,
    isSuccess: isPublishSuccess,
  } = usePublishTournamentMutation();

  const onEdit = () => {
    navigate({
      to: '/club/$clubId/tournament/$tournamentId/edit',
      params: { clubId, tournamentId },
    });
  };

  const onPublish = useCallback(async () => {
    const result = await publishTournament({ clubId, tournamentId });
    if (!result) report();
  }, [publishTournament, clubId, tournamentId, report]);

  useImmediateEffect(() => {
    if (isPublishSuccess) {
      refetch();
    }
  }, [isPublishSuccess]);

  const isPending = isClubPending || isTournamentPending;

  const { refetch: onDownloadXlsResults } = useTournamentResultXlsQuery(
    clubId,
    tournamentId,
  );

  const { mutateAsync: accept, isPending: isAcceptPending } =
    useParticipantActionTournamentMutation('accept', clubId, tournamentId);
  const onAccept = useCallback(async () => {
    await accept();
    refetch();
    refParticipants.current?.refetch();
  }, [accept, refParticipants, refetch]);

  const { mutateAsync: join, isPending: isJoinPending } =
    useParticipantActionTournamentMutation('join', clubId, tournamentId);
  const onJoin = useCallback(async () => {
    await join();
    refetch();
    refParticipants.current?.refetch();
  }, [join, refParticipants, refetch]);

  const { mutateAsync: leave, isPending: isLeavePending } =
    useParticipantActionTournamentMutation('leave', clubId, tournamentId);
  const onLeave = useCallback(async () => {
    await leave();
    refetch();
    refParticipants?.current?.refetch();
  }, [leave, refParticipants, refetch]);
  const isActionPending = isAcceptPending || isLeavePending || isJoinPending;

  // Отслеживание изменений турнира
  useUserSocket((e) => {
    switch (e.eventType) {
      case 'TournamentStartsSoon':
      case 'TournamentFinished':
        if (e.tournamentId === tournamentId) {
          updateTournamentCardQuery(ql, clubId, tournamentId, e.tournament);
        }
        break;
      case 'RoundDrafted':
      case 'RoundStarted':
      case 'RoundFinished':
        if (e.tournamentId === tournamentId) {
          refetch();
        }
        break;
    }
  });

  const { isEditable, isPublishable } = useTournamentStates(tournament ?? null);

  return {
    club: {
      logoUrl: club?.logoUrl,
      memberStatus: club?.memberStatus,
    },
    tournament,
    isPending,
    isManager,
    isEditable,
    isPublishable,
    isPublishing,
    onEdit,
    onPublish,
    onDownloadXlsResults,
    isActionPending,
    onAccept,
    onLeave,
    onJoin,
  };
};
