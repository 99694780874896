import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { IPgnMove } from '@libs/chess/pgn';
import { Props } from '../PgnEditor';
import {
  getNavigationMoves,
  getSimilarAlt,
  mapModel,
  updateMoves,
} from '../model';

/*
    ____,-------------------------------,____
    \   |             Хук               |   /
    /___|-------------------------------|___\
*/

export function usePgnEditor(
  externalMoves: IPgnMove[] | undefined,
  {
    noInlineVariants = false,
    readonlyMainVariant = false,
    enableGlobalShortcuts = false,
    initialSelected,
  }: {
    noInlineVariants?: boolean;
    readonlyMainVariant?: boolean;
    enableGlobalShortcuts?: boolean;
    initialSelected?: IPgnMove | null;
  } = {},
) {
  // Actual moves

  const [moves, setMoves] = useState(externalMoves ?? []);

  // FIXME: избавиться от двойного рендера из-за setMoves()
  useEffect(() => {
    setMoves((m) => {
      if (!externalMoves && !m.length) return m;

      if (m !== externalMoves) {
        setSelected(undefined);
        setShowMenu(undefined);
        historyRef.current = [];
      }

      return externalMoves ?? [];
    });
  }, [externalMoves]);

  // Props

  const model = useMemo(
    () => mapModel(moves, !noInlineVariants),
    [moves, noInlineVariants],
  );
  const [selected, setSelected] = useState<IPgnMove | undefined>(
    initialSelected ?? undefined,
  );
  const [showMenu, setShowMenu] = useState<IPgnMove | undefined>();

  // Store
  const _store = {
    moves,
    selected,
    readonlyMainVariant,
    saveUndoSnapshot: (moves: IPgnMove[]) => {
      const h = historyRef.current;
      const p = historyPosRef.current;

      if (p === null) {
        h.push({ moves });
      } else if (h[p]?.moves !== moves) {
        historyRef.current = h.slice(0, p + 1).concat({ moves });
        historyPosRef.current = null;
      }
    },
  };
  const ref = useRef(_store);
  ref.current = _store;

  const { first, last, next, prev, playSequence } = useMemo(
    () => getNavigationMoves(selected, moves, !noInlineVariants),
    [selected, moves, noInlineVariants],
  );

  // History (undo + redo)
  const historyRef = useRef<{ moves: IPgnMove[]; selected?: IPgnMove }[]>([
    { moves, selected },
  ]);
  const historyPosRef = useRef<number | null>(null);
  const canUndo = Boolean(
    historyRef.current.length > 1 &&
      (historyPosRef.current === null || historyPosRef.current > 0),
  );
  const canRedo = Boolean(
    historyPosRef.current !== null &&
      historyPosRef.current < historyRef.current.length - 1,
  );
  useEffect(() => {
    const h = historyRef.current;
    const p = historyPosRef.current;
    const cur = p === null ? h[h.length - 1] : h[p];

    if (cur && moves === cur?.moves) {
      cur.selected = selected;
    }
  }, [moves, selected]);

  const onUndo = useCallback(() => {
    const h = historyRef.current;
    const p = historyPosRef.current ?? h.length - 1;

    const prevP = p - 1;
    const prevMoves = h[prevP];
    if (prevMoves) {
      historyPosRef.current = prevP;
      setMoves(prevMoves.moves);
      setSelected(prevMoves.selected);
      setShowMenu(undefined);
    }
  }, []);

  const onRedo = useCallback(() => {
    const h = historyRef.current;
    const p = historyPosRef.current;

    if (p === null) return;

    const nextP = p + 1;
    const nextMoves = h[nextP];
    if (nextMoves) {
      historyPosRef.current = nextP;
      setMoves(nextMoves.moves);
      setSelected(nextMoves.selected);
      setShowMenu(undefined);
    }
  }, []);

  const onMoveSelect = useCallback((m: IPgnMove | undefined) => {
    setSelected(m);
    setShowMenu(undefined);
  }, []);

  const onMenuOpen = useCallback((m: IPgnMove) => {
    setSelected(m);
    setShowMenu(m);
  }, []);

  const onMenuClose = useCallback(() => {
    setShowMenu(undefined);
  }, []);

  // При любых изменених:
  // 1. Пересобрать список moves; Переиспользовать незименные ходы
  //    1.1. из полухода получить путь до основной ветки
  //    1.2. пересобрать все родительские ходы и списки в них
  // 2. Пересобрать модель; FIXME: Переиспользовать модели, ходы которых не
  //    поменялись

  const movesRef = useRef(moves);
  movesRef.current = moves;

  const addMove = useCallback((san: string, m?: IPgnMove) => {
    const moves = movesRef.current;
    const readonlyMainVariant = ref.current.readonlyMainVariant;

    // невозможно создать подвариант, если нет ходов
    if (readonlyMainVariant && !moves.length) return;

    if (!m) {
      const same = getSimilarAlt(san, moves[0]);
      if (same) {
        setSelected(same);
      } else {
        const newMove: IPgnMove = {
          color: moves[0]?.color === 'black' ? 'black' : 'white',
          moveFull: moves[0]?.moveFull ?? 1,
          san,
        };
        const firstMove = moves[0] ? { ...moves[0] } : null;
        if (firstMove) {
          firstMove.alts = firstMove.alts
            ? [...firstMove.alts, [newMove]]
            : [[newMove]];
        }
        const nextMoves = firstMove
          ? [firstMove, ...moves.slice(1)]
          : [newMove];
        setMoves(nextMoves);
        setSelected(newMove);
        ref.current.saveUndoSnapshot(nextMoves);
      }
    } else {
      const { nextMoves, nextSelected } = updateMoves(m, ref.current.moves, {
        action: 'add',
        readonlyMainVariant,
        san,
      });
      setMoves(nextMoves);
      setSelected(nextSelected);
      ref.current.saveUndoSnapshot(nextMoves);
      setShowMenu(undefined);
    }
  }, []);

  const onMoveDelete = useCallback(
    (m: IPgnMove) => {
      const mustPromote = m.alts && (m.alts.length > 1 || noInlineVariants);
      const { nextMoves, nextSelected } = updateMoves(
        m,
        ref.current.moves,
        mustPromote
          ? {
              action: 'promote',
              variant: 0,
            }
          : { action: 'delete' },
      );
      setMoves(nextMoves);
      setSelected(nextSelected);
      ref.current.saveUndoSnapshot(nextMoves);
      setShowMenu(undefined);
    },
    [noInlineVariants],
  );

  const onMoveCommentCreate = useCallback((m: IPgnMove) => {
    setShowMenu(undefined);
    const { nextMoves, nextSelected } = updateMoves(m, ref.current.moves, {
      action: 'update',
      data: { comment: '' },
    });
    setMoves(nextMoves);
    setSelected(nextSelected);
    // sic! пока коммент пустой — не создаём снэпшот
    // ref.current.saveUndoSnapshot(nextMoves, nextSelected);
    setShowMenu(undefined);
  }, []);

  const onMoveCommentUpdate = useCallback((comment: string, m: IPgnMove) => {
    if (m.comment === comment) return;

    const { nextMoves, nextSelected } = updateMoves(m, ref.current.moves, {
      action: 'update',
      data: { comment: normalizeComment(comment) },
    });
    setMoves(nextMoves);
    if (ref.current.selected === m) setSelected(nextSelected);
    ref.current.saveUndoSnapshot(nextMoves);
  }, []);

  const onVariantCommentCreate = useCallback((m: IPgnMove) => {
    setShowMenu(undefined);
    const { nextMoves, nextSelected } = updateMoves(m, ref.current.moves, {
      action: 'update',
      data: { commentBefore: '' },
    });
    setMoves(nextMoves);
    setSelected(nextSelected);
    // sic! пока коммент пустой — не создаём снэпшот
    // ref.current.saveUndoSnapshot(nextMoves, nextSelected);
    setShowMenu(undefined);
  }, []);

  const onVariantCommentUpdate = useCallback((comment: string, m: IPgnMove) => {
    if (m.commentBefore === comment) return;

    const { nextMoves, nextSelected } = updateMoves(m, ref.current.moves, {
      action: 'update',
      data: { commentBefore: normalizeComment(comment) },
    });
    setMoves(nextMoves);
    if (ref.current.selected === m) setSelected(nextSelected);
    ref.current.saveUndoSnapshot(nextMoves);
  }, []);

  const onNagsUpdate = useCallback(
    (nags: number[] | undefined, m: IPgnMove) => {
      const { nextMoves, nextSelected } = updateMoves(m, ref.current.moves, {
        action: 'update',
        data: { nags },
      });
      setMoves(nextMoves);
      setSelected(nextSelected);
      ref.current.saveUndoSnapshot(nextMoves);
      setShowMenu(undefined);
    },
    [],
  );

  const props: Props = {
    model,
    selected,
    showMenu,
    first,
    last,
    next,
    prev,
    //
    canUndo,
    canRedo,
    //
    onMoveSelect,
    onMenuOpen,
    onMenuClose,
    onMoveDelete,
    onMoveCommentCreate,
    onMoveCommentUpdate,
    onVariantCommentCreate,
    onVariantCommentUpdate,
    onNagsUpdate,
    onUndo,
    onRedo,
  };

  // GlobalShortcuts
  const propsRef = useRef(props);
  propsRef.current = props;
  useEffect(() => {
    if (!enableGlobalShortcuts) return;

    function onKeyboard(e: KeyboardEvent) {
      const props = propsRef.current;
      const focused = document.activeElement;

      if (
        focused &&
        ((focused.nodeName === 'INPUT' &&
          !['checkbox', 'radio'].includes(focused.getAttribute('type')!)) ||
          focused.nodeName === 'TEXTAREA' ||
          focused.nodeName === 'SELECT' ||
          focused.getAttribute('contentEditable'))
      )
        return;

      if (e.code === 'ArrowRight' && props.next) {
        props.onMoveSelect?.(props.next);
        e.preventDefault();
      } else if (e.code === 'ArrowLeft' && props.prev !== undefined) {
        props.onMoveSelect?.(props.prev ?? undefined);
        e.preventDefault();
      } else if (e.code === 'KeyZ' && (e.ctrlKey || e.metaKey)) {
        e.shiftKey ? props.onRedo?.() : props.onUndo?.();
        e.preventDefault();
      }
    }

    window.addEventListener('keydown', onKeyboard);

    return () => window.removeEventListener('keydown', onKeyboard);
  }, [enableGlobalShortcuts]);

  return {
    moves,
    playSequence,
    addMove,
    props,
  };
}

/*
    ____,-------------------------------,____
    \   |            Утилиты            |   /
    /___|-------------------------------|___\
*/

function normalizeComment(comment?: string) {
  if (!comment || !comment.trim()) return undefined;
  return comment?.replaceAll(/[\r\n}]/g, '');
}
