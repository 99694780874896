import {
  memo,
  ReactNode,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react';
import { cx } from '@libs/classnames';
import { useMutationObserver } from '@libs/hooks/useMutationObserver';
import { SlimArrowButton } from '@ui/components/SlimArrowButton';
import styles from './WideScreenNavigation.module.css';

type WideScreenNavigationProps = {
  className?: string;
  pageClassName: string;
  currentPage: number;
  setCurrentPage: (page: number) => void;
  children: ReactNode;
};

const CONTAINER_ID = 'wideScreenNavigation';
const MAX_DEPTH = 10;

export const WideScreenNavigation = memo(function WideScreenNavigationInternal({
  className,
  pageClassName,
  currentPage,
  setCurrentPage,
  children,
}: WideScreenNavigationProps) {
  const [animationsDisabled, setAnimationsDisabled] = useState(false);
  const [currentLeftShift, setCurrentLeftShift] = useState(0);
  const [pagesCount, setPagesCount] = useState(0);
  const containerRef = useRef<HTMLDivElement>(null);
  // TODO maybe React to resize?

  const recalculateOffset = useCallback(() => {
    const pages = getPages(pageClassName);
    setPagesCount(pages.length);

    const page = pages[currentPage];
    if (!page) return;
    const offset = getOffsetRelativeToContainer(page);
    setCurrentLeftShift(offset);
  }, [pageClassName, currentPage, setPagesCount, setCurrentLeftShift]);

  useMutationObserver(containerRef, () => {
    // Disable animations hack. Node changes, resizes can lead to change of offset.
    // But we do not want to make any surprising animations, taking root from something,
    // that user didn't do.
    setAnimationsDisabled(true);
    recalculateOffset();

    // Timeout required, to skip render, before re-enabling animations again, transition is complete.
    setTimeout(() => {
      setAnimationsDisabled(false);
    });
  });

  useEffect(() => {
    recalculateOffset();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentPage]);

  const isOnLastPage = currentPage === Math.max(0, pagesCount - 1);

  const onLeftClick = useCallback(
    () => setCurrentPage(currentPage - 1),
    [currentPage, setCurrentPage],
  );
  const onRightClick = useCallback(
    () => setCurrentPage(currentPage + 1),
    [currentPage, setCurrentPage],
  );

  return (
    <>
      <div className={cx(styles.wideScreenNavigation, className)}>
        <div
          id={CONTAINER_ID}
          className={cx(styles.wideScreenNavigationContent, {
            [styles.animationsDisabled]: animationsDisabled,
          })}
          ref={containerRef}
          style={{ transform: `translateX(-${currentLeftShift}px)` }}
        >
          {children}
        </div>
      </div>
      <div className={styles.wideScreenNavigationButtons}>
        <SlimArrowButton
          className={cx(styles.button, styles.left)}
          direction="left"
          onClick={onLeftClick}
          disabled={currentPage === 0}
        />
        <SlimArrowButton
          className={cx(styles.button, styles.right)}
          direction="right"
          onClick={onRightClick}
          disabled={isOnLastPage}
        />
      </div>
    </>
  );
});

function getPages(pageClassName: string): HTMLElement[] {
  const self = document?.getElementById('wideScreenNavigation');
  const pagesCollection = self?.getElementsByClassName(pageClassName);
  return pagesCollection ? (Array.from(pagesCollection) as HTMLElement[]) : [];
}

function getOffsetRelativeToContainer(element: HTMLElement): number {
  if (!element) return 0;
  let offset = 0;
  let level = 0;
  let currentElement: HTMLElement | null = element;
  while (
    level < MAX_DEPTH &&
    currentElement &&
    currentElement.id !== CONTAINER_ID &&
    currentElement.tagName !== 'body'
  ) {
    offset = offset + currentElement.offsetLeft;
    currentElement = currentElement.parentElement;
    level++;
  }
  return offset;
}
