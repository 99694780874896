import {
  createContext,
  CSSProperties,
  Fragment,
  memo,
  MutableRefObject,
  useContext,
  useEffect,
  useLayoutEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { createPortal } from 'react-dom';
import { autoUpdate, flip, useFloating } from '@floating-ui/react-dom';
import { IPgnMove, nagToSymbol } from '@libs/chess/pgn';
import { cx } from '@libs/classnames';
import { useClickOutside } from '@libs/hooks/useClickOutside';
import { indexToAlpha } from '@libs/numbers';
import { Subscription } from '@libs/subscription';
import { ReactNode } from '@tanstack/react-router';
import { AnimatedNode } from '@ui/components/Animation';
import { IconBack, IconFastBack } from '@ui/icons/PgnContols';
import styles from './PgnEditor.module.css';
import {
  EMPTY_MODEL,
  IModelFullMove,
  IModelHalfMove,
  IModelMain,
  IModelStub,
  IModelVariant,
} from './model';

/*
    ____,-------------------------------,____
    \   |          Компоненты           |   /
    /___|-------------------------------|___\
*/

// TODO: придумать как зачёркивать ходы при ховере корзины, которые будут
// удалены вместе с выбранным

export type Props = {
  className?: string;
  style?: CSSProperties;
  //
  model?: IModelMain;
  //
  noCommentIdent?: boolean;
  //
  result?: string | null;
  selected?: IPgnMove;
  showMenu?: IPgnMove;
  next?: IPgnMove;
  last?: IPgnMove;
  prev?: IPgnMove | null; // null — значит можно перейти на 0 ход (сбросить selected)
  first?: IPgnMove | null;
  //
  canUndo?: boolean;
  canRedo?: boolean;
  //
  onMoveSelect?: (m: IPgnMove | undefined) => void;
  onMenuOpen?: (m: IPgnMove) => void;
  onMenuClose?: () => void;
  onMoveDelete?: (m: IPgnMove) => void;
  onMoveCommentCreate?: (m: IPgnMove) => void;
  onMoveCommentUpdate?: (comment: string, m: IPgnMove) => void;
  onVariantCommentCreate?: (m: IPgnMove) => void;
  onVariantCommentUpdate?: (comment: string, m: IPgnMove) => void;
  onNagsUpdate?: (nags: number[] | undefined, m: IPgnMove) => void;
  onUndo?: () => void;
  onRedo?: () => void;
};

type IPgnContext = {
  subscriptionFocus: Subscription<IPgnMove | undefined>;
  refMenuPortal: MutableRefObject<HTMLDivElement | null>;
  refSelected: MutableRefObject<HTMLDivElement | null>;
  //
  onMoveSelect?: Props['onMoveSelect'];
  onMenuOpen?: Props['onMenuOpen'];
  onMenuClose?: Props['onMenuClose'];
  onMoveDelete?: Props['onMoveDelete'];
  onMoveCommentCreate?: Props['onMoveCommentCreate'];
  onMoveCommentUpdate?: Props['onMoveCommentUpdate'];
  onVariantCommentCreate?: Props['onVariantCommentCreate'];
  onVariantCommentUpdate?: Props['onVariantCommentUpdate'];
  onNagsUpdate?: Props['onNagsUpdate'];
};

type IPgnSelectionContext = {
  selectedMove?: IPgnMove;
  menuMove?: IPgnMove;
};

const PgnContext = createContext<IPgnContext | undefined>(undefined);
const PgnSelectionContext = createContext<IPgnSelectionContext>({});

export function PgnEditor({
  className,
  style,
  //
  model = EMPTY_MODEL,
  //
  noCommentIdent = false,
  //
  result,
  selected,
  showMenu,
  next,
  prev,
  first,
  last,
  //
  canUndo,
  canRedo,
  //
  onMoveSelect,
  onMenuOpen,
  onMenuClose,
  onMoveDelete,
  onMoveCommentCreate,
  onMoveCommentUpdate,
  onVariantCommentCreate,
  onVariantCommentUpdate,
  onNagsUpdate,
  onUndo,
  onRedo,
}: Props) {
  const subscriptionFocus = useMemo(
    () => new Subscription<IPgnMove | undefined>(),
    [],
  );
  const refMenuPortal = useRef<HTMLDivElement>(null);
  const { refScrollable } = useScrollToLatest({
    count: model.count,
    result,
    selected,
    // FIXME: пока отключим, чтобы подумать как подружить с useFocusSelected
    disabled: true,
  });
  const { refSelected } = useFocusSelected(selected, refScrollable);
  const context = useMemo<IPgnContext>(
    () => ({
      subscriptionFocus,
      refMenuPortal,
      refSelected,
      //
      onMoveSelect,
      onMenuOpen,
      onMenuClose,
      onMoveDelete,
      onMoveCommentCreate,
      onMoveCommentUpdate,
      onVariantCommentCreate,
      onVariantCommentUpdate,
      onNagsUpdate,
    }),
    [
      subscriptionFocus,
      refSelected,
      onMoveSelect,
      onMenuOpen,
      onMenuClose,
      onMoveDelete,
      onMoveCommentCreate,
      onMoveCommentUpdate,
      onVariantCommentCreate,
      onVariantCommentUpdate,
      onNagsUpdate,
    ],
  );
  const contextSelection = useMemo<IPgnSelectionContext>(
    () => ({ selectedMove: selected, menuMove: showMenu }),
    [selected, showMenu],
  );

  return (
    <PgnContext.Provider value={context}>
      <PgnSelectionContext.Provider value={contextSelection}>
        <section className={cx(styles.pgn, className)} style={style}>
          <div className={styles.pgn__controls}>
            <Controls
              count={model.count}
              next={next}
              prev={prev}
              first={first}
              last={last}
            />
          </div>
          <div className={styles.pgn__moves} ref={refScrollable}>
            <div className={styles.pgn__moves__portal} ref={refMenuPortal} />
            <div className={styles.pgn__moves__clip}>
              {model.moveWithComment && (
                <BlockComment
                  move={model.moveWithComment}
                  mode="before"
                  noCommentIdent={true}
                />
              )}
              {model.moves.map((fm) => (
                <FullMove
                  key={`${fm.white.move.moveFull}.${fm.white.move.san}`}
                  move={fm}
                  noCommentIdent={noCommentIdent}
                />
              ))}
              {model.moves.length === 0 && <EmptyFullMove />}
              {result && <div className={styles.result}>{result}</div>}
            </div>
          </div>
          <AnimatedNode>
            {(canUndo || canRedo) && (
              <UndoControls
                className={styles.pgn__undo}
                canUndo={canUndo}
                canRedo={canRedo}
                onUndo={onUndo}
                onRedo={onRedo}
              />
            )}
          </AnimatedNode>
        </section>
      </PgnSelectionContext.Provider>
    </PgnContext.Provider>
  );
}

function Controls({
  count,
  next,
  prev,
  first,
  last,
}: {
  count: number;
  next?: IPgnMove;
  last?: IPgnMove;
  prev?: IPgnMove | null; // null — значит можно перейти на 0 ход (сбросить selected)
  first?: IPgnMove | null;
}) {
  const { onMoveSelect } = usePgnContext();
  return (
    <ul className={styles.controls}>
      <li className={cx(styles.controls__btn, styles.is_left)}>
        <button
          disabled={first === undefined}
          onClick={() => onMoveSelect?.(first ?? undefined)}
        >
          <IconFastBack />
        </button>
      </li>
      <li className={cx(styles.controls__btn, styles.is_left)}>
        <button
          className={styles.red}
          disabled={prev === undefined}
          onClick={() => onMoveSelect?.(prev ?? undefined)}
        >
          <IconBack />
        </button>
      </li>
      <li className={styles.controls__counter}>{count}</li>
      <li className={cx(styles.controls__btn, styles.is_right)}>
        <button disabled={!next} onClick={() => onMoveSelect?.(next)}>
          <IconBack />
        </button>
      </li>
      <li className={cx(styles.controls__btn, styles.is_right)}>
        <button disabled={!last} onClick={() => onMoveSelect?.(last)}>
          <IconFastBack />
        </button>
      </li>
    </ul>
  );
}

const FullMove = memo(function FullMove({
  move,
  noCommentIdent,
}: {
  move: IModelFullMove;
  noCommentIdent: boolean;
}) {
  const { white, black, branches } = move;
  const commentedHalfMove = (() => {
    if (white.type === 'move' && white.move.comment !== undefined)
      return white.move;
    if (black?.type === 'move' && black.move.comment !== undefined)
      return black.move;
  })();
  const counter = white?.move.moveFull ?? black?.move.moveFull;

  return (
    <div className={styles.full_move}>
      <div className={styles.full_move__moves}>
        <div className={styles.full_move__moves__counter}>{counter}</div>
        <div className={styles.full_move__moves__half_move}>
          <HalfMoveConnected move={white} noNumber />
          <HalfMoveAttributes move={white} />
        </div>
        <div className={styles.full_move__moves__half_move}>
          {black && <HalfMoveConnected move={black} noNumber />}
          {black && <HalfMoveAttributes move={black} />}
        </div>
      </div>
      {commentedHalfMove && (
        <BlockComment
          move={commentedHalfMove}
          noCommentIdent={noCommentIdent}
          mode="after"
        />
      )}
      {branches && (
        <div className={styles.full_move__alts}>
          {branches.map((a, i) => (
            <Variant key={i} variant={a} label={indexToAlpha(i)} />
          ))}
        </div>
      )}
    </div>
  );
});

const EmptyFullMove = memo(function EmptyFullMove() {
  return (
    <div className={styles.full_move}>
      <div className={styles.full_move__moves}>
        <div className={styles.full_move__moves__counter}>1</div>
        <div className={styles.full_move__moves__half_move}>—</div>
        <div className={styles.full_move__moves__half_move} />
      </div>
    </div>
  );
});

function BlockComment({
  move,
  mode,
  noCommentIdent,
}: {
  move: IPgnMove;
  mode: 'before' | 'after';
  noCommentIdent: boolean;
}) {
  return (
    <div
      className={cx(styles.block_comment, {
        [styles.is_white]: !noCommentIdent && move.color === 'white',
        [styles.is_black]: !noCommentIdent && move.color === 'black',
      })}
    >
      <EditableCommentText move={move} mode={mode} />
    </div>
  );
}

// Вынесен в отдельный компонент, чтобы обрабатывать контекст (selected и menu)
function HalfMoveConnected({
  className,
  move,
  noNumber,
}: {
  className?: string;
  move: IModelHalfMove | IModelStub;
  noNumber?: boolean;
}) {
  const halfmove = move.type === 'move' ? move.move : undefined;
  const { selectedMove, menuMove } = useContext(PgnSelectionContext);
  const selected = Boolean(halfmove && halfmove === selectedMove);
  const isMenuOpen = Boolean(halfmove && halfmove === menuMove);
  return (
    <HalfMove
      className={className}
      move={move}
      noNumber={noNumber}
      selected={selected}
      isMenuOpen={isMenuOpen}
    />
  );
}

const HalfMove = memo(function HalfMove({
  className,
  move,
  noNumber,
  selected,
  isMenuOpen,
}: {
  className?: string;
  move: IModelHalfMove | IModelStub;
  noNumber?: boolean;
  selected: boolean;
  isMenuOpen: boolean;
}) {
  const halfmove = move.type === 'move' ? move.move : undefined;
  const number =
    noNumber || move.move.color === 'black' ? '' : move.move.moveFull;
  const isStub = move.type === 'stub';

  const { refSelected, onMoveSelect, onMenuOpen } = usePgnContext();

  const refSan = useRef<HTMLButtonElement>(null);

  useEffect(() => {
    if (selected)
      refSan.current?.focus({
        preventScroll: true,
      });
  }, [selected]);

  return (
    <div
      className={cx(styles.half_move, className, {
        [styles.is_white]: move.move.color === 'white',
        [styles.is_black]: move.move.color === 'black',
        [styles.is_no_pointer]: isStub,
        [styles.is_selected]: selected,
        [styles.is_menu_open]: isMenuOpen,
        [styles.is_stub]: isStub,
      })}
      ref={selected ? refSelected : undefined}
    >
      {number && <span className={styles.half_move__counter}>{number}</span>}

      {/* SAN */}
      <button
        tabIndex={isStub ? -1 : undefined}
        ref={refSan}
        className={styles.half_move__san}
        onFocus={() => {
          if (!halfmove || selected) return;
          onMoveSelect?.(halfmove);
        }}
        onPointerDown={() => {
          if (!halfmove) return;
          if (selected && !isMenuOpen) {
            onMenuOpen?.(halfmove);
          } else {
            onMoveSelect?.(halfmove);
          }
        }}
        onContextMenu={(e) => {
          e.preventDefault();
          if (halfmove) onMenuOpen?.(halfmove);
        }}
      >
        <span>{move.move.san}</span>
      </button>

      {/* NAGs */}
      {halfmove?.nags && (
        <NagsList
          className={styles.half_move__nags}
          nags={halfmove.nags}
          onPointerDown={isMenuOpen ? undefined : () => onMenuOpen?.(halfmove)}
        />
      )}

      {/* Menu */}
      {isMenuOpen && halfmove && (
        <MoveMenuPositioner refNode={refSan}>
          <MoveMenu move={halfmove} />
        </MoveMenuPositioner>
      )}
    </div>
  );
});

function NagsList({
  className,
  nags,
  onPointerDown,
}: {
  className?: string;
  nags: number[];
  onPointerDown?: () => void;
}) {
  return (
    <div
      className={cx(styles.nags_list, className)}
      onPointerDown={onPointerDown}
    >
      {nags.map((n, i) => (
        <Nag key={`${i}-${n}`} nag={n} />
      ))}
    </div>
  );
}

// TODO: превратить все символы из меню в svg и добавить title
export function Nag({ className, nag }: { className?: string; nag: number }) {
  const symbol = nagToSymbol(nag);

  if (!symbol) return null;

  return (
    <span
      className={cx(styles.nag, className, styles[`is_${nag}`], {
        [styles.is_two_char]: symbol.length === 2,
      })}
    >
      <span>{symbol}</span>
    </span>
  );
}

function HalfMoveAttributes({ move }: { move: IModelHalfMove | IModelStub }) {
  if (move.type === 'stub') return null;

  const m = move.move;
  const entries: string[] = [];

  if (m.score !== undefined) {
    entries.push(m.score.toString().replace('.', ','));
  }

  if (m.timeElapsed !== undefined) {
    entries.push(`${m.timeElapsed}c`);
  }

  return (
    <span
      className={cx(styles.half_move_attributes, {
        [styles.is_white]: move.move.color === 'white',
        [styles.is_black]: move.move.color === 'black',
      })}
    >
      {entries.map((e, i) => (
        <span key={i}>{e}</span>
      ))}
    </span>
  );
}

const Variant = memo(function Variant({
  label,
  variant,
  inline,
}: {
  label?: string;
  variant: IModelVariant;
  inline?: boolean;
}) {
  const brackets = variant.level % 2 === 1 ? '[]' : '()';
  const [folded, setFolded] = useState(false);

  if (folded)
    return (
      <div
        className={cx(styles.variant, {
          [styles.is_inline]: inline,
          [styles.is_labeled]: label,
          [styles.is_lined]: !label && !inline,
        })}
        onClick={() => setFolded((f) => !f)}
      >
        {label && <span className={styles.variant__label}>{label}</span>}
        <div className={styles.variant__folded}>
          {brackets[0]}+{brackets[1]}
        </div>
      </div>
    );

  return (
    <div
      className={cx(styles.variant, {
        [styles.is_inline]: inline,
        [styles.is_labeled]: label,
        [styles.is_lined]: !label && !inline,
      })}
    >
      <span
        className={styles.variant__label}
        onClick={() => setFolded((f) => !f)}
      >
        {label ? label : brackets[0]}
      </span>
      <div className={styles.variant__blocks}>
        {variant.entries.map((e, i) => {
          if (e.type === 'block') {
            return (
              <div key={i} className={styles.variant__blocks__block}>
                {i === 0 && variant.moveWithComment && (
                  <span className={styles.variant__blocks__block__comment}>
                    <EditableCommentText
                      move={variant.moveWithComment}
                      mode="before"
                    />
                  </span>
                )}
                {e.moves.map((m, j) => (
                  <Fragment key={j}>
                    {m.move.color === 'white' && j > 0 && (
                      <span className={styles.variant__move_separator}></span>
                    )}
                    <HalfMoveConnected
                      className={styles.variant__blocks__block__halfmove}
                      move={m}
                    />
                    {m.move.comment !== undefined && (
                      <span className={styles.variant__blocks__block__comment}>
                        <EditableCommentText move={m.move} mode="after" />
                      </span>
                    )}
                  </Fragment>
                ))}
              </div>
            );
          } else if (e.type === 'variant') {
            return <Variant key={i} variant={e} inline />;
          }
        })}

        {!label && (
          <span
            className={styles.variant__label}
            onClick={() => setFolded((f) => !f)}
          >
            {brackets[1]}
          </span>
        )}
        {variant.branches?.map((b, i) => <Variant key={i} variant={b} />)}
      </div>
    </div>
  );
});

// FIXME: safari (иногда) криво ставит фокус в новый (пустой) коммент
function EditableCommentText({
  move,
  mode,
}: {
  move: IPgnMove;
  mode?: 'before' | 'after';
}) {
  const { onMoveCommentUpdate, onVariantCommentUpdate, subscriptionFocus } =
    usePgnContext();
  const onChange =
    mode === 'after' ? onMoveCommentUpdate : onVariantCommentUpdate;
  const children = mode === 'after' ? move.comment : move.commentBefore;

  const ref = useRef<HTMLSpanElement>(null);

  useEffect(() => {
    const el = ref.current;
    if (!el || !onChange) return;

    // Подписка на focus
    const unsubscribeFocus = subscriptionFocus.subscribe((m) => {
      if (move !== m) return;
      el.focus();
      const sel = window.getSelection();
      const range = document.createRange();
      range.selectNodeContents(el);
      sel?.removeAllRanges();
      sel?.addRange(range);
    });

    // Режим редактирования

    try {
      el.contentEditable = 'plaintext-only';
    } catch {
      // sic! workaround для firefox, который не поддерживает 'plaintext-only'
      el.contentEditable = 'true';
    }

    // Автофокус на новый (пустой) комментарий

    if (!el.innerText.trim()) {
      el.focus();
    }

    // События

    // - блокировка перевода строки
    const onKeyDown = (e: KeyboardEvent) => {
      if (e.key === 'Enter') {
        e.preventDefault();
        el.blur();
      }
    };
    el.addEventListener('keydown', onKeyDown);

    // - удаление переводов строк из буфера обмена
    const onPaste = (e: ClipboardEvent) => {
      const data = e.clipboardData?.getData('text/plain');
      const sanitized = data?.replaceAll(/[\r\n]/g, '');
      if (sanitized && sanitized !== data) {
        e.preventDefault();
        e.stopPropagation();
        const selection = window.getSelection();
        if (selection?.rangeCount) {
          selection.deleteFromDocument();
          selection
            .getRangeAt(0)
            .insertNode(document.createTextNode(sanitized));
          selection.collapseToEnd();
        }
      }
    };
    el.addEventListener('paste', onPaste);

    return () => {
      unsubscribeFocus();
      el.removeEventListener('keydown', onKeyDown);
      el.removeEventListener('paste', onPaste);
    };
  }, [move, onChange, subscriptionFocus]);

  return (
    <span
      ref={ref}
      className={styles.editable_comment_text}
      onBlur={(e) => onChange?.(e.target.innerText, move)}
    >
      {children}
    </span>
  );
}

// Вынесен как отдельный элемент, чтобы ход и меню не перерисовывались лишний
// раз при сролле
function MoveMenuPositioner({
  children,
  refNode,
}: {
  children: ReactNode;
  refNode: MutableRefObject<HTMLButtonElement | null>;
}) {
  const floating = useFloating({
    placement: 'bottom-start',
    whileElementsMounted: autoUpdate,
    middleware: [flip()],
  });

  const { refMenuPortal } = usePgnContext();
  useEffect(() => {
    if (refNode.current) floating.refs.setReference(refNode.current);
  }, [floating.refs, refNode]);

  return (
    refMenuPortal.current &&
    createPortal(
      <div
        className={cx(styles.move_menu_positioner, styles[floating.placement])}
        ref={floating.refs.setFloating}
        style={{
          ...floating.floatingStyles,
          zIndex: 2,
          // workaround для случаев с дёрганием при первом открытии (случается редко)
          visibility: floating.isPositioned ? 'visible' : 'hidden',
        }}
      >
        {/* TODO: тут отлично бы смотрелась анимация появления со scale из угла */}
        {children}
      </div>,
      refMenuPortal.current,
    )
  );
}

const MoveMenu = memo(function MoveMenu({ move }: { move: IPgnMove }) {
  const {
    onMoveDelete,
    onMoveCommentCreate,
    onMenuClose,
    onNagsUpdate,
    subscriptionFocus,
  } = usePgnContext();
  const ref = useClickOutside<HTMLDivElement>(onMenuClose);

  const nagsEssential = useMemo(() => [1, 2, 3, 4, 5, 6], []);
  const nagsOther = useMemo(
    () => [
      20, 16, 14, 15, 17, 21,
      //
      12, 13, 256, 146, 23, 138,
      //
      41, 37, 133, 140,
    ],
    [],
  );
  const nags = useMemo(() => {
    const s = new Set([...nagsEssential, ...nagsOther]);
    // добавим недостающие из хода
    move.nags?.forEach((n) => s.add(n));
    return Array.from(s);
  }, [move.nags, nagsEssential, nagsOther]);

  return (
    <div
      ref={ref}
      className={cx(styles.move_menu, {
        [styles.is_white]: move.color === 'white',
        [styles.is_black]: move.color === 'black',
      })}
    >
      <div className={styles.move_menu__head}>
        <button
          className={cx(styles.move_menu__head__san, styles.half_move__san)}
          onClick={onMenuClose}
          onContextMenu={(e) => {
            e.preventDefault();
            onMenuClose?.();
          }}
        >
          <span>{move.san}</span>
        </button>
        <span className={styles.move_menu__head__icons}>
          <IconComment
            className={styles.move_menu__comment}
            onClick={() => {
              if (move.comment) {
                subscriptionFocus.publish(move);
                onMenuClose?.();
              } else {
                onMoveCommentCreate?.(move);
              }
            }}
          />
          <IconDelete
            className={styles.move_menu__delete}
            onClick={() => onMoveDelete?.(move)}
          />
        </span>
      </div>
      <div className={styles.move_menu__nags}>
        {nags.map((n) => {
          const selected = move.nags?.includes(n);
          return (
            <span
              key={n}
              className={cx(styles.move_menu__nags__nag, {
                [styles.is_selected]: selected,
              })}
              onClick={() => {
                if (selected) {
                  onNagsUpdate?.(
                    move.nags?.filter((x) => x !== n),
                    move,
                  );
                } else {
                  const isEssential = nagsEssential.includes(n);
                  const filtered =
                    move.nags?.filter((x) =>
                      isEssential
                        ? !nagsEssential.includes(x)
                        : nagsEssential.includes(x),
                    ) ?? [];

                  onNagsUpdate?.(
                    isEssential ? [n, ...filtered] : [...filtered, n],
                    move,
                  );
                }
              }}
            >
              <Nag nag={n} />
            </span>
          );
        })}
      </div>
    </div>
  );
});

function UndoControls({
  className,
  canUndo,
  canRedo,
  onUndo,
  onRedo,
}: {
  className?: string;
  canUndo?: boolean;
  canRedo?: boolean;
  onUndo?: () => void;
  onRedo?: () => void;
}) {
  return (
    <div className={cx(styles.undo_controls, className)}>
      <div className={styles.undo_controls__wrapper}>
        <div
          className={cx(styles.undo_controls__undo, {
            [styles.is_disabled]: !canUndo,
          })}
          onClick={onUndo}
        >
          <IconUndo />
        </div>
        <div
          className={cx(styles.undo_controls__redo, {
            [styles.is_disabled]: !canRedo,
          })}
          onClick={onRedo}
        >
          <IconUndo />
        </div>
      </div>
    </div>
  );
}

/*
    ____,-------------------------------,____
    \   |             Иконки            |   /
    /___|-------------------------------|___\
*/

function IconDelete({
  className,
  onClick,
}: {
  className?: string;
  onClick?: () => void;
}) {
  return (
    <svg
      className={className}
      onClick={onClick}
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 16 16"
      width="16"
    >
      <path
        fill="currentColor"
        d="M15.186 3.613h-3.502l-.552-1.928A2.322 2.322 0 0 0 8.899 0H7.11a2.323 2.323 0 0 0-2.233 1.685l-.552 1.928H.822a.805.805 0 0 0-.818.697.774.774 0 0 0 .774.851h.988L2.8 13.948A2.323 2.323 0 0 0 5.109 16h5.79a2.322 2.322 0 0 0 2.308-2.052l1.032-8.787h.99a.774.774 0 0 0 .775-.851.805.805 0 0 0-.818-.697ZM7.108 1.548h1.794a.774.774 0 0 1 .743.563l.423 1.502H5.94l.429-1.502a.774.774 0 0 1 .74-.563Zm3.794 12.904H5.108a.774.774 0 0 1-.774-.684L3.328 5.16h9.355l-1.015 8.607a.774.774 0 0 1-.769.684h.003Z"
      />
    </svg>
  );
}

function IconComment({
  className,
  onClick,
}: {
  className?: string;
  onClick?: () => void;
}) {
  return (
    <svg
      className={className}
      onClick={onClick}
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 16 15"
      width="16"
    >
      <path
        fill="currentColor"
        fillRule="evenodd"
        d="M.008 6.996c0-3.843 3.59-6.968 8-6.968l.002.004c4.41 0 8 3.125 8 6.968s-3.59 6.968-8 6.968c-.87 0-1.724-.121-2.536-.356l-.124-.036-1.314.986a2.195 2.195 0 0 1-3.51-1.755v-.068c0-.207.028-.41.083-.607l.46-1.657-.062-.098a6.3 6.3 0 0 1-1-3.381ZM5.33 11.93a7.466 7.466 0 0 0 2.677.485l-.002.003c3.558 0 6.451-2.431 6.451-5.42 0-2.987-2.893-5.419-6.451-5.419-3.559 0-6.452 2.432-6.452 5.42 0 1.03.346 2.03.996 2.895a.68.68 0 0 1 .12.599l-.571 2.054a.675.675 0 0 0-.027.19v.068a.646.646 0 0 0 1.033.516L4.865 12a.499.499 0 0 1 .466-.07Zm3.45-5.711h1.55c.425 0 .773.348.773.774a.777.777 0 0 1-.774.774H8.782v1.548a.777.777 0 0 1-.774.774.777.777 0 0 1-.775-.774V7.767H5.685a.777.777 0 0 1-.774-.774c0-.426.349-.774.774-.774h1.548V4.67c0-.425.35-.774.775-.774.425 0 .774.349.774.774V6.22Z"
        clipRule="evenodd"
      />
    </svg>
  );
}

function IconUndo({
  className,
  onClick,
}: {
  className?: string;
  onClick?: () => void;
}) {
  return (
    <svg
      className={className}
      onClick={onClick}
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 13 12"
      width="13"
    >
      <path
        fill="#000"
        d="M.558 2.52A1.891 1.891 0 0 0 0 3.86a1.883 1.883 0 0 0 .558 1.338l2.28 2.267a.863.863 0 0 0 .94.186.863.863 0 0 0 .532-.793.853.853 0 0 0-.252-.606L2.513 4.715h5.46a2.81 2.81 0 0 1 1.982.815 2.777 2.777 0 0 1 0 3.94 2.81 2.81 0 0 1-1.981.816h-6.25a.865.865 0 0 0-.61.25.855.855 0 0 0 .61 1.464h6.25a4.55 4.55 0 0 0 3.2-1.318A4.498 4.498 0 0 0 12.5 7.5a4.478 4.478 0 0 0-1.326-3.182A4.527 4.527 0 0 0 7.974 3H2.512l1.546-1.536A.855.855 0 0 0 3.448 0a.865.865 0 0 0-.61.251l-2.28 2.27Z"
      />
    </svg>
  );
}

/*
    ____,-------------------------------,____
    \   |              Hooks            |   /
    /___|-------------------------------|___\
*/

function usePgnContext() {
  return useContext(PgnContext)!;
}

function useScrollToLatest({
  count,
  result,
  selected,
  disabled = false,
}: {
  count: number;
  result?: string | null;
  selected?: IPgnMove;
  disabled?: boolean;
}) {
  const refScrollable = useRef<HTMLDivElement>(null);

  const _store = useRef({ count, result, selected, disabled, rendered: false });
  _store.current.selected = selected;
  _store.current.disabled = disabled;

  useLayoutEffect(
    () => {
      const store = _store.current;

      const mustScroll =
        !store.disabled &&
        refScrollable.current &&
        !store.selected &&
        (count !== store.count || result !== store.result);

      if (mustScroll) {
        refScrollable.current.scrollTo({
          top: refScrollable.current.scrollHeight,
          behavior: store.rendered ? 'smooth' : 'instant',
        });
      }

      _store.current = { ...store, count, result, rendered: true };
    },
    /* Скроллим при изменении кол-ва ходов или изменении результата */
    [count, result],
  );

  return { refScrollable };
}

function useFocusSelected(
  selected: IPgnMove | undefined,
  refScrollable: MutableRefObject<HTMLDivElement | null>,
) {
  const refMounted = useRef(false);

  const refSelected = useRef<HTMLDivElement>(null);
  useEffect(() => {
    const wasMounted = refMounted.current;
    const t = setTimeout(() => {
      if (!refSelected.current) {
        refScrollable.current?.scrollTo({
          top: 0,
          behavior: 'smooth',
        });
      } else {
        refSelected.current.scrollIntoView({
          block: wasMounted ? 'nearest' : 'center',
          behavior: wasMounted ? 'smooth' : 'instant',
        });
      }
    }, 0);
    return () => clearTimeout(t);
  }, [refScrollable, selected]);

  useEffect(() => {
    refMounted.current = true;
  }, []);

  return {
    refSelected,
  };
}
