import { KnockoutRoundType } from '@features/shared/api/typings';
import { Fraction } from '@ui/components/Fraction';

export type Props = {
  roundType: KnockoutRoundType;
  roundName?: string | null;
  preset?: 'short' | 'full';
};

export function KnockoutRoundName({
  roundType,
  roundName,
  preset = 'short',
}: Props) {
  if (!roundName) return null;
  roundName = '1/' + roundName;
  if (roundType === 'ThirdPlace') return '3-е место';
  if (roundType === 'Final' || roundName === '1/1') return 'Финал';

  if (roundName.indexOf('/') !== -1) {
    if (preset === 'full')
      return (
        <>
          <Fraction value={roundName} />
          &nbsp;финала
        </>
      );
    else return <Fraction value={roundName} />;
  }

  return roundName;
}
