import { ReactNode, useCallback } from 'react';
import {
  ClockControlType,
  MemberDto,
  ParticipantDto,
} from '@features/shared/api/typings';
import { cx } from '@libs/classnames';
import { HorizontalScroll } from '@ui/components/HorizontalScroll';
import { InlineInput } from '@ui/components/InlineInput';
import { Table, TableCell, TableRow } from '@ui/components/Table';
import styles from './CardParticipantList.module.css';

const timeControls: { [k in ClockControlType]: string } = {
  Blitz: 'Блиц',
  Rapid: 'Рапид',
  Classic: 'Классика',
};

type Group = {
  id: string;
  name: string;
};

// FIXME: разъединить клуб и турнир

export type Props = {
  className?: string;
  hideNumbers?: boolean;
  preset: 'club' | 'tournament';
  groups?: Group[];
  participants?: ParticipantDto[] | MemberDto[];
  clockControl?: ClockControlType;
  renderGroupActions?: (group: Group) => ReactNode;
  renderParticipantActions?: (
    participant: ParticipantDto | MemberDto,
  ) => ReactNode;
  isManager: boolean;
  highlightedUserId?: string;
  groupTitle?: string;
  onGroupTitleChange?: (newGroupTitle: string | null) => void;
  onMemberGroupNameChange?: (
    userId: string,
    newGroupName: string | null,
  ) => void;
  memberGroupNameOptions?: string[];
};

export function CardParticipantList({
  className,
  hideNumbers,
  groups,
  participants,
  preset,
  clockControl,
  renderGroupActions,
  renderParticipantActions,
  isManager,
  highlightedUserId,
  groupTitle,
  onGroupTitleChange,
  onMemberGroupNameChange,
  memberGroupNameOptions,
}: Props) {
  const participantsByGroupId: Record<string, (ParticipantDto | MemberDto)[]> =
    {};
  participants?.forEach((p) => {
    let groupId: string = 'free';
    if (preset === 'tournament') {
      groupId = (p as ParticipantDto).teamId || 'free';
    }
    if (!participantsByGroupId[groupId]) participantsByGroupId[groupId] = [];
    participantsByGroupId[groupId]!.push(p);
  });

  const allRatingsVisible = preset !== 'tournament' || !clockControl;

  return (
    <HorizontalScroll>
      <Table className={cx(className, styles.participantsList)}>
        <TableRow header>
          <TableCell colSpan={hideNumbers ? 2 : 3}>Имя</TableCell>
          <TableCell className={styles.title}></TableCell>
          {preset === 'club' && (
            <>
              <TableCell>
                {isManager ? (
                  <InlineInput
                    value={groupTitle || 'Группа'}
                    onChange={onGroupTitleChange}
                  />
                ) : (
                  groupTitle
                )}
              </TableCell>
              <TableCell leftGap className={styles.tournamentsCount} right>
                Турниры
              </TableCell>
              <TableCell leftGap right>
                Игры
              </TableCell>
            </>
          )}

          <TableCell colSpan={allRatingsVisible ? 3 : 2} centered>
            Рейтинг
          </TableCell>
          <TableCell>{/* renderActions */}</TableCell>
        </TableRow>

        {allRatingsVisible && (
          <TableRow subHeader>
            <TableCell
              colSpan={hideNumbers ? 3 : 4 + (preset === 'club' ? 3 : 0)}
            />
            <TableCell right>Клас.</TableCell>
            <TableCell right>Рапид</TableCell>
            <TableCell right>Блиц</TableCell>
            <TableCell />
          </TableRow>
        )}

        {participantsByGroupId['free'] &&
          participantsByGroupId['free'].length > 0 &&
          participantsByGroupId['free'].map((p, i) => (
            <ParticipantListItem
              key={p.userId}
              hideNumbers={hideNumbers}
              no={++i}
              participant={p}
              preset={preset}
              clockControl={clockControl}
              renderActions={renderParticipantActions}
              isManager={isManager}
              highlighted={p.userId === highlightedUserId}
              onMemberGroupNameChange={onMemberGroupNameChange}
              memberGroupNameOptions={memberGroupNameOptions}
            />
          ))}

        {groups &&
          groups.length > 0 &&
          groups.map((g, i) => (
            <GroupListItem
              key={g.id}
              hideNumbers={hideNumbers}
              no={i + 1}
              preset={preset}
              group={g}
              clockControl={clockControl}
              participants={participantsByGroupId[g.id] || []}
              renderGroupActions={renderGroupActions}
              renderParticipantActions={renderParticipantActions}
              isManager={isManager}
              highlightedUserId={highlightedUserId}
              onMemberGroupNameChange={onMemberGroupNameChange}
              memberGroupNameOptions={memberGroupNameOptions}
            />
          ))}
      </Table>
      {(!participants || !participants.length) && (
        <div className={styles.placeholder}>Нет участников</div>
      )}
    </HorizontalScroll>
  );
}

type GroupListItemProps = {
  no: number;
  hideNumbers?: boolean;
  group: Group;
  preset: 'club' | 'tournament';
  clockControl?: ClockControlType;
  participants: (ParticipantDto | MemberDto)[];
  renderGroupActions?: (group: Group) => ReactNode;
  renderParticipantActions?: (
    participant: ParticipantDto | MemberDto,
  ) => ReactNode;
  isManager: boolean;
  highlightedUserId?: string;
  onMemberGroupNameChange?: (userId: string, groupName: string | null) => void;
  memberGroupNameOptions?: string[];
};

function GroupListItem({
  no,
  hideNumbers,
  group,
  preset,
  clockControl,
  participants,
  renderGroupActions,
  renderParticipantActions,
  isManager,
  highlightedUserId,
  onMemberGroupNameChange,
  memberGroupNameOptions,
}: GroupListItemProps) {
  const allRatingsVisible = preset !== 'tournament' || !clockControl;

  return (
    <>
      <TableRow group hoverable>
        {hideNumbers ? null : <TableCell>{no}</TableCell>}
        <TableCell colSpan={2}>{group.name}</TableCell>
        <TableCell colSpan={preset === 'club' ? 4 : 1}>
          {/* tournament and games count */}
        </TableCell>
        <TableCell colSpan={allRatingsVisible ? 3 : 1} right>
          {/* rank */}
        </TableCell>

        <TableCell leftGap right>
          {renderGroupActions && renderGroupActions(group)}
        </TableCell>
      </TableRow>

      {participants.length > 0 &&
        participants.map((p, i) => (
          <ParticipantListItem
            key={p.userId}
            hideNumbers={hideNumbers}
            no={i + 1}
            participant={p}
            preset={preset}
            clockControl={clockControl}
            renderActions={renderParticipantActions}
            isManager={isManager}
            highlighted={p.userId === highlightedUserId}
            onMemberGroupNameChange={onMemberGroupNameChange}
            memberGroupNameOptions={memberGroupNameOptions}
          />
        ))}
    </>
  );
}

type ParticipantListItemProps = {
  no?: number;
  hideNumbers?: boolean;
  participant: MemberDto | ParticipantDto;
  preset: 'club' | 'tournament';
  clockControl?: ClockControlType;
  renderActions?: (participant: ParticipantDto | MemberDto) => ReactNode;
  isManager: boolean;
  highlighted?: boolean;
  onMemberGroupNameChange?: (
    userId: string,
    newGroupName: string | null,
  ) => void;
  memberGroupNameOptions?: string[];
};
function ParticipantListItem({
  no,
  hideNumbers,
  participant,
  preset,
  clockControl,
  renderActions,
  isManager,
  highlighted,
  onMemberGroupNameChange,
  memberGroupNameOptions,
}: ParticipantListItemProps) {
  const blitz = participant.elo.blitz.rating;
  const rapid = participant.elo.rapid.rating;
  const classic = participant.elo.classic.rating;

  let time = (
    <>
      <TableCell leftGap slim right>
        {classic} ·
      </TableCell>
      <TableCell slim right>
        {rapid} ·
      </TableCell>
      <TableCell slim right>
        {blitz}
      </TableCell>
    </>
  );
  if (preset === 'tournament' && clockControl) {
    const timeSystem = timeControls[clockControl];
    let rank;
    if (clockControl === 'Classic') rank = classic;
    if (clockControl === 'Rapid') rank = rapid;
    if (clockControl === 'Blitz') rank = blitz;

    time = (
      <>
        <TableCell slim leftGap right>
          {timeSystem} ∙
        </TableCell>
        <TableCell slim right>
          {rank}
        </TableCell>
      </>
    );
  }

  const groupName = (participant as MemberDto).groupName || '';

  const onGroupNameChange = useCallback(
    (newGroupName: string | null) => {
      onMemberGroupNameChange?.(participant.userId, newGroupName);
    },
    [onMemberGroupNameChange, participant.userId],
  );

  return (
    <TableRow hoverable active={highlighted}>
      {hideNumbers ? null : <TableCell>{no}</TableCell>}
      <TableCell className={styles.nameColumn}>
        {participant.lastName} {participant.firstName}
      </TableCell>
      <TableCell>
        {isManager && preset === 'tournament' ? participant.email : ''}
      </TableCell>
      <TableCell>{participant.title}</TableCell>
      {preset === 'club' && (
        <>
          <TableCell>
            {isManager ? (
              <InlineInput
                value={groupName}
                onChange={onGroupNameChange}
                options={memberGroupNameOptions}
              />
            ) : (
              groupName
            )}
          </TableCell>
          <TableCell right>{participant.tournamentsCount}</TableCell>
          <TableCell right>{participant.gamesCount}</TableCell>
        </>
      )}
      {time}

      <TableCell leftGap right>
        {renderActions && renderActions(participant)}
      </TableCell>
    </TableRow>
  );
}
