import { useCallback, useState, KeyboardEvent } from 'react';
import { autoUpdate, flip, offset, useFloating } from '@floating-ui/react-dom';
import { cx } from '@libs/classnames';
import { Card } from '@ui/components/Card';
import { HighlightedText } from '@ui/components/HighlightedText';
import { Input } from '@ui/components/Input';
import { InlineEditIcon } from '@ui/icons/InlineEditIcon';
import styles from './InlineInput.module.css';

export type Props = {
  className?: string;
  value?: string | null;
  onChange?: (value: string | null) => void;
  disabled?: boolean;
  options?: string[];
};

export function InlineInput({
  className,
  value,
  onChange,
  disabled,
  options,
}: Props) {
  const [editModeEnabled, setEditModeEnabled] = useState(false);
  const [editValue, setEditValue] = useState<string>(value || '');
  const [keyPosition, setKeyPosition] = useState<number | null>(null);

  const filteredOptions = options?.filter(
    (o) => !editValue || o.toLowerCase().includes(editValue.toLowerCase()),
  );

  const handleTextInput = useCallback(
    (newValue: string) => {
      setKeyPosition(null);
      setEditValue(newValue);
    },
    [setEditValue],
  );

  const onCancel = useCallback(() => {
    setKeyPosition(null);
    setEditValue(value || '');
    setEditModeEnabled(false);
  }, [value, setEditValue, setEditModeEnabled]);

  const onEditClick = useCallback(() => {
    setEditModeEnabled(true);
  }, [setEditModeEnabled]);

  const onSave = useCallback(() => {
    // Dropdown selection mode
    if (filteredOptions && keyPosition !== null) {
      // Cancel button selected
      if (keyPosition === filteredOptions.length) {
        onCancel();
        return;
      }

      if (filteredOptions[keyPosition]) {
        setKeyPosition(null);
        setEditValue(filteredOptions[keyPosition]);
        onChange?.(filteredOptions[keyPosition]);
        setEditModeEnabled(false);
        return;
      }
    }

    // Save value from text input
    onChange?.(editValue || null);
    setEditModeEnabled(false);
  }, [
    onChange,
    setEditValue,
    onCancel,
    keyPosition,
    filteredOptions,
    editValue,
  ]);

  const onOptionClick = useCallback(
    (newValue: string) => {
      setEditValue(newValue);
      onChange?.(newValue || null);
      setEditModeEnabled(false);
    },
    [setEditValue, onChange, setEditModeEnabled],
  );

  const onOkClick = useCallback(() => {
    setKeyPosition(null);
    onChange?.(editValue);
    setEditModeEnabled(false);
  }, [onChange, editValue, setEditModeEnabled]);

  const okButton = (
    <span className={styles.okButton} onMouseDown={onOkClick}>
      OK
    </span>
  );

  const onDown = useCallback(
    (e: KeyboardEvent) => {
      e.preventDefault();
      if (!filteredOptions) return;
      if (keyPosition === null) return setKeyPosition(0);
      setKeyPosition(
        keyPosition === filteredOptions.length ? 0 : keyPosition + 1,
      );
    },
    [filteredOptions, keyPosition, setKeyPosition],
  );

  const onUp = useCallback(
    (e: KeyboardEvent) => {
      e.preventDefault();
      if (!filteredOptions) return;
      if (!keyPosition) return setKeyPosition(filteredOptions.length);
      setKeyPosition(keyPosition - 1);
    },
    [filteredOptions, keyPosition, setKeyPosition],
  );

  const floating = useFloating({
    placement: 'bottom-start',
    whileElementsMounted: autoUpdate,
    middleware: [flip(), offset(4)],
  });

  return (
    <span
      ref={floating.refs.setReference}
      className={cx(
        styles.inlineInputContainer,
        { [styles.editModeActive]: editModeEnabled },
        className,
      )}
    >
      {editModeEnabled ? (
        <>
          <Input
            autoFocus
            className={styles.input}
            onChange={handleTextInput}
            value={editValue || ''}
            disabled={disabled}
            rightLabel={okButton}
            onBlur={onCancel}
            onEnter={onSave}
            onEscape={onCancel}
            onDown={onDown}
            onUp={onUp}
          />
          {filteredOptions && filteredOptions.length > 0 && (
            <Card
              className={styles.optionList}
              ref={floating.refs.setFloating}
              style={floating.floatingStyles}
            >
              {filteredOptions.map((o, i) => (
                <div
                  key={o}
                  className={cx(styles.option, {
                    [styles.selected]: keyPosition === i,
                  })}
                  onMouseDown={(e) => {
                    e.stopPropagation();
                    onOptionClick(o);
                  }}
                >
                  <HighlightedText text={o} highlight={editValue || ''} />
                </div>
              ))}
              <hr className={styles.hr} />
              <div
                className={cx(styles.option, {
                  [styles.selected]: keyPosition === filteredOptions.length,
                })}
                onClick={onCancel}
              >
                Отмена
              </div>
            </Card>
          )}
        </>
      ) : (
        <>
          <span className={styles.text}>{editValue}</span>
          <span className={styles.editButton} onClick={onEditClick}>
            <InlineEditIcon />
          </span>
        </>
      )}
    </span>
  );
}
