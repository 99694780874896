export class Subscription<T> {
  private subscribers: Set<(cb: T) => void> = new Set();

  constructor(last?: T) {
    this.last = last;
  }

  last?: T;

  get size(): number {
    return this.subscribers.size;
  }

  subscribe = (cb: (arg: T) => void): (() => void) => {
    this.subscribers.add(cb);
    return () => this.unsubscribe(cb);
  };

  unsubscribe = (cb: (arg: T) => void): void => {
    this.subscribers.delete(cb);
  };

  publish = (message: T): void => {
    this.last = message;
    this.subscribers.forEach((cb) => cb(message));
  };

  clear = (): void => {
    this.subscribers.clear();
  };
}
