import { useCallback, useState } from 'react';
import { components } from '@features/shared/api/club/generated';
import { ClockControlType, Tournament } from '@features/shared/api/typings';
import {
  useCreateTournamentTeamMutation,
  useRearrangeParticipantsMutation,
  useRemoveFromTournamentMutation,
  useRemoveTournamentTeamMutation,
  useTournamentParticipantsQuery,
} from '@features/tournament/api/hooks';
import { ParticipantsModal } from '../ParticipantsModal';
import { useParticipantsModal } from '../ParticipantsModal/useParticipantsModal';
import { TournamentParticipantsEdit } from './TournamentParticipantsEdit';

/*
    ____,-------------------------------,____
    \   |          Компонент            |   /
    /___|-------------------------------|___\
*/

type Props = {
  tournament: Tournament;
  disabled?: boolean;
  isTeam?: boolean;
  clockControl?: ClockControlType;
  tab: ParticipantsTab;
  onTab: (newTab: ParticipantsTab) => void;
  onTournamentRefetch: () => void;
};
type ParticipantsTab = 'Invited' | 'Participating' | 'Exited' | 'Banned';

export function TournamentParticipantsEditWidget({
  tournament,
  disabled,
  isTeam = tournament.mode === 'Team',
  clockControl = tournament.clockControl,
  tab,
  onTournamentRefetch: refetchTournament,
  onTab,
}: Props) {
  const { clubId, tournamentId } = tournament;

  const {
    data,
    refetch: refetchParticipants,
    isPending,
  } = useTournamentParticipantsQuery(clubId, tournamentId, tab);

  const { onCreateTeam, onRemoveTeam, onRemoveParticipant, onRearrange } =
    useMutations(clubId, tournamentId, refetchTournament, refetchParticipants);

  const {
    modalProps,
    modalVisible,
    onModalVisibleChange,
    hideParticipantsModal,
  } = useModal(clubId, tournamentId, refetchParticipants);

  return (
    <TournamentParticipantsEdit
      tab={tab}
      isPending={isPending}
      participants={data}
      isTeam={isTeam}
      teams={tournament.teams}
      maxPerTeam={tournament.teamModeSettings?.size || 8}
      clockControl={clockControl || tournament.clockControl}
      disabled={disabled}
      tournamentEntrance={tournament.entrance}
      modal={
        <ParticipantsModal
          {...modalProps}
          onDone={hideParticipantsModal}
          disabled={disabled}
          clockControl={tournament?.clockControl || 'Classic'}
          allowAdd={tab === 'Participating'}
          allowInvite={tab === 'Invited'}
        />
      }
      modalVisible={modalVisible}
      setModalVisible={onModalVisibleChange}
      onTabChange={onTab}
      onCreateTeam={onCreateTeam}
      onRemoveTeam={onRemoveTeam}
      onRemoveParticipant={onRemoveParticipant}
      onRearrange={onRearrange}
    />
  );
}

/*
    ____,-------------------------------,____
    \   |           Запчасти            |   /
    /___|-------------------------------|___\
*/

function useMutations(
  clubId: string,
  tournamentId: string,
  refetchTournament: () => void,
  refetchParticipants: () => void,
) {
  const { mutateAsync: removeParticipant } = useRemoveFromTournamentMutation(
    clubId,
    tournamentId,
  );
  const { mutateAsync: removeTeam } = useRemoveTournamentTeamMutation(
    clubId,
    tournamentId,
  );
  const { mutateAsync: createTeam } = useCreateTournamentTeamMutation(
    clubId,
    tournamentId,
  );
  const { mutateAsync: rearrange } = useRearrangeParticipantsMutation(
    clubId,
    tournamentId,
  );

  const onCreateTeam = useCallback(
    async (newTeamName: string) => {
      await createTeam({ name: newTeamName });
      refetchTournament();
    },
    [createTeam, refetchTournament],
  );

  const onRemoveTeam = useCallback(
    async (teamId: string) => {
      await removeTeam({ teamId });
      refetchParticipants();
      refetchTournament();
    },
    [removeTeam, refetchTournament, refetchParticipants],
  );

  const onRemoveParticipant = useCallback(
    async (userId: string) => {
      await removeParticipant({ userId });
      refetchParticipants();
    },
    [removeParticipant, refetchParticipants],
  );

  const onRearrange = useCallback(
    (items: components['schemas']['RearrangeParticipantsRequestItem'][]) => {
      rearrange({ items });
    },
    [rearrange],
  );

  return {
    onCreateTeam,
    onRemoveTeam,
    onRemoveParticipant,
    onRearrange,
  };
}

// ------------------------------------------

function useModal(
  clubId: string,
  tournamentId: string,
  refetchParticipants: () => void,
) {
  const [modalVisible, setModalVisible] = useState(false);
  const onModalVisibleChange = useCallback(
    (newValue: boolean) => {
      setModalVisible(newValue);
      refetchParticipants();
    },
    [setModalVisible, refetchParticipants],
  );
  const hideParticipantsModal = useCallback(() => {
    setModalVisible(false);
    refetchParticipants();
  }, [setModalVisible, refetchParticipants]);

  const modalProps = useParticipantsModal({ clubId, tournamentId });

  return {
    modalVisible,
    onModalVisibleChange,
    hideParticipantsModal,
    modalProps,
  };
}
