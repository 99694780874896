import { useCallback } from 'react';
import { useClubRoles } from '@features/auth/hooks/useClubRoles';
import {
  useClubCardQuery,
  useMemberGroupsQuery,
  useUpdateClubMutation,
  useUpdateMemberGroupMutation,
} from '@features/club/api/hooks';

export function useClubGroupProps(clubId: string) {
  const { isManager } = useClubRoles(clubId);
  const { data: club } = useClubCardQuery(clubId);

  const { mutateAsync: updateClub } = useUpdateClubMutation(clubId);
  const handleGroupTitleChange = useCallback(
    (groupTitle: string | null) => {
      updateClub({ groupTitle });
    },
    [updateClub],
  );
  const { data: memberGroupNameOptions, refetch: refetchGroups } =
    useMemberGroupsQuery(clubId, isManager);

  const { mutateAsync: updateMemberGroup } =
    useUpdateMemberGroupMutation(clubId);
  const handleMemberGroupNameChange = useCallback(
    async (userId: string, groupName: string | null) => {
      await updateMemberGroup({ userId, groupName });
      await refetchGroups();
    },
    [updateMemberGroup, refetchGroups],
  );

  return {
    groupTitle: club?.groupTitle || '',
    onGroupTitleChange: handleGroupTitleChange,
    onMemberGroupNameChange: handleMemberGroupNameChange,
    memberGroupNameOptions: memberGroupNameOptions,
  };
}
