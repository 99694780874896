import { Fragment, useState } from 'react';
import {
  KnockoutResultsMatch,
  KnockoutResultsRound,
  KnockoutRoundType,
} from '@features/shared/api/typings';
import { BracketsMatch } from '@features/tournament/ui/components/BracketsMatch';
import { KnockoutRoundName } from '@features/tournament/ui/components/KnockoutRoundName';
import { cx } from '@libs/classnames';
import { useMediaQuery } from '@ui/components/MediaQueryProvider';
import { WideScreenNavigation } from '@ui/components/WideScreenNavigation/WideScreenNavigation';
import styles from './EliminationBrackets.module.css';

export type EliminationBracketsProps = {
  className?: string;
  rounds: KnockoutResultsRound[];
  onGameClick?: (gameId: string) => void;
};

const wideNavigationClassName = 'roundWideNavigation';

export function EliminationBrackets({
  className,
  rounds,
  onGameClick,
}: EliminationBracketsProps) {
  const [currentPage, setCurrentPage] = useState(0);

  return (
    <div className={cx(styles.brackets, className)}>
      <WideScreenNavigation
        pageClassName={wideNavigationClassName}
        currentPage={currentPage}
        setCurrentPage={setCurrentPage}
      >
        <div
          className={styles.bracket}
          style={{
            gridTemplateColumns: `repeat(${rounds.length}, auto)`, // Dynamic columns
            gridTemplateRows: `repeat(${Math.pow(2, rounds.length - 1)}, auto)`, // Dynamic rows
          }}
        >
          {rounds
            .filter(
              (r) => r.roundType === 'WinnerBranch' || r.roundType === 'Final',
            )
            .map((r, i) => {
              return (
                <EliminationRound
                  key={r.roundName}
                  roundIndex={++i}
                  roundName={r.roundName}
                  roundPosition={i - currentPage}
                  roundType={r.roundType}
                  matches={r.matches}
                  onGameClick={onGameClick}
                />
              );
            })}
        </div>
      </WideScreenNavigation>
    </div>
  );
}

type EliminationRoundProps = {
  roundIndex: number;
  roundName: string;
  roundPosition?: number;
  roundType: KnockoutRoundType;
  matches: KnockoutResultsMatch[];
  onGameClick?: (gameId: string) => void;
};
function EliminationRound({
  roundIndex,
  roundName,
  roundType,
  matches,
  roundPosition = 1,
  onGameClick,
}: EliminationRoundProps) {
  const isMobile = useMediaQuery() === 'mobile';

  return (
    <>
      {matches.map((m, i) => {
        const gridColumn = roundIndex;
        const gridRow =
          Math.pow(2, roundIndex) * i + Math.pow(2, roundIndex - 1) + 1;
        const isHidden = roundPosition - 1 < 0;
        return (
          <Fragment
            key={
              'match' +
              m.player1?.userId +
              m.player1?.lastName +
              m.player2?.userId +
              m.player2?.lastName
            }
          >
            <div
              className={cx(styles.match, {
                [wideNavigationClassName]: i === 0,
                [styles.hidden]: isHidden,
              })}
              style={{
                gridColumn,
                gridRow: roundPosition < 0 ? 1 : gridRow,
              }}
            >
              <BracketsMatch
                roundName={
                  <KnockoutRoundName
                    roundName={roundName}
                    roundType={m.knockoutRoundType || roundType}
                    preset={isMobile ? 'full' : 'short'}
                  />
                }
                player1={m.player1}
                player2={m.player2}
                isFinal={m.knockoutRoundType === 'Final'}
                isThirdPlace={m.knockoutRoundType === 'ThirdPlace'}
                onGameClick={onGameClick}
              />
            </div>
          </Fragment>
        );
      })}
    </>
  );
}
