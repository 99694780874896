import { useCallback, useEffect, useState } from 'react';
import { useClubRoles } from '@features/auth/hooks/useClubRoles';
import {
  resetClubEventsQuery,
  useClubEventsQuery,
} from '@features/club/api/hooks';
import { ClubEventList } from '@features/club/ui/ClubEventList';
import { useUserSocket } from '@features/shared/api';
import { useQueryClient } from '@tanstack/react-query';
import { useNavigate } from '@tanstack/react-router';
import { useMediaQuery } from '@ui/components/MediaQueryProvider';

export type Props = {
  className?: string;
  clubId: string;
  tab: 'upcoming' | 'finished';
  onTabChange: (tab: 'upcoming' | 'finished') => void;
};

export function ClubEventListWidget({
  className,
  clubId,
  tab,
  onTabChange,
}: Props) {
  const isMobile = useMediaQuery() === 'mobile';
  const [currentPage, setCurrentPage] = useState(0);

  const navigate = useNavigate();
  const onTournament = useCallback(
    (tournamentId: string) => {
      navigate({
        to: '/club/$clubId/tournament/$tournamentId',
        params: { clubId, tournamentId },
      });
    },
    [navigate, clubId],
  );

  const { isManager, isMember } = useClubRoles(clubId);

  const { data, refetch, isPending, fetchNextPage, hasNextPage } =
    useClubEventsQuery(clubId, tab, isManager, currentPage);

  // TODO remove frontend sorting, after BE fix
  const events = data?.sort((a, b) => {
    if (!b.startTimestampMs || !a.startTimestampMs) return 1;

    if (tab === 'upcoming') {
      if (a.promoted && !b.promoted) return -1;
      if (!a.promoted && b.promoted) return 1;

      // upcoming - closest to current date go first
      return a.startTimestampMs - b.startTimestampMs;
    }

    // finished - from latest to oldest.
    return b.startTimestampMs - a.startTimestampMs;
  });

  useUserSocket((e) => {
    if (tab !== 'upcoming') return;
    switch (e.eventType) {
      case 'TournamentPublished':
      case 'TournamentUpdated':
        refetch();
        break;
    }
  });

  const qc = useQueryClient();

  useEffect(() => {
    setCurrentPage(0);
    resetClubEventsQuery(qc, clubId, tab, isManager);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tab]);

  const onLoadMore = useCallback(() => {
    setCurrentPage(currentPage + 1);
    setTimeout(() => fetchNextPage());
  }, [setCurrentPage, currentPage, fetchNextPage]);

  // TournamentUpdated
  return (
    <ClubEventList
      className={className}
      tab={tab}
      loading={isPending}
      events={events}
      onTab={onTabChange}
      onTournament={onTournament}
      onLoadMore={hasNextPage && !isMobile ? onLoadMore : undefined}
      isClubMember={isMember}
    />
  );
}
